import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig(source) {
    return {
      cancelToken: source?.token,
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static createWONote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/wo/create", requestData, this.requestConfig());
  }

  static createMainNote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/main/create", requestData, this.requestConfig());
  }

  static createCallCustomerNote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/call/create", requestData, this.requestConfig());
  }

  static createAttachmentNote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/attachment/create", requestData, this.requestConfig());
  }

  static createRecurringCarNote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/recurring_car/create", requestData, this.requestConfig());
  }

  static createParkingNote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/parking_note/create", requestData, this.requestConfig());
  }

  static updateParkingNote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/parking_note/update", requestData, this.requestConfig());
  }

  static updateWONote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/wo/update", requestData, this.requestConfig());
  }

  static updateMainNote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/main/update", requestData, this.requestConfig());
  }

  static updateCallCustomerNote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/call/update", requestData, this.requestConfig());
  }

  static updateAttachmentNote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/attachment/update", requestData, this.requestConfig());
  }

  static updateRecurringCarNote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/recurring_car/update", requestData, this.requestConfig());
  }

  static deleteAppointmentNote(appointment_note_id) {
    return axios.post(ENV.appointmentApiBase + "/notes/delete", { appointment_note_id }, this.requestConfig());
  }

  static getAppointments(requestData) {
    return axios.post(ENV.apiBaseB + "/appointments/list_daily", requestData, this.requestConfig());
  }

  static getAppointment(id) {
    // hack
    id = parseInt(id, 10);
    return axios.post(
      ENV.apiBaseB + "/appointments/view",
      { id, embed: "appointment_status,customer_owner,customer_driver,car,customer_contract,customcom,pin,snooze,deskcom,keycom,kioskcom" },
      this.requestConfig()
    );
  }

  static getAppointmentByWo(wo, dealer_location_id) {
    return axios.post(
      ENV.apiBaseB + "/appointments/view_by_wo",
      { wo, dealer_location_id, embed: "appointment_status,customer_owner,customer_driver,car,customer_contract,customcom,pin,snooze,deskcom" },
      this.requestConfig()
    );
  }

  static cancelAppointment(requestData) {
    return axios.post(ENV.apiBaseB + "/appointments/cancel", requestData, this.requestConfig());
  }

  static createAppointment(requestData) {
    return axios.post(ENV.importerBaseUrl + "/appointments/import", requestData, this.requestConfig());
  }

  static getAppointmentChecks(id) {
    return axios.post(ENV.apiBaseB + `/checks/list`, { appointment_id: id, embed: "pin,snooze" }, this.requestConfig());
  }

  static updateAppointment(requestData) {
    return axios.post(ENV.apiBaseB + "/appointments/update", requestData, this.requestConfig());
  }

  static updateIntervention(requestData) {
    return axios.post(ENV.appointmentApiBase + "/interventions/update", requestData, this.requestConfig());
  }

  static addIntervention(requestData) {
    return axios.post(ENV.appointmentApiBase + "/interventions/add", requestData, this.requestConfig());
  }

  static deleteIntervention(id) {
    return axios.post(ENV.appointmentApiBase + "/interventions/delete", { id }, this.requestConfig());
  }

  static appendAttachment(requestData) {
    return axios.post(ENV.apiBaseB + "/appointments/append_attachment", requestData, this.requestConfig());
  }

  static updateStatus(requestData) {
    return axios.post(ENV.apiBaseB + "/appointments/new_status", requestData, this.requestConfig());
  }

  static updateQuestion(requestData) {
    return axios.post(ENV.apiBaseB + "/checks/update_question_item", requestData, this.requestConfig());
  }

  static updateQuestionResultRemark(requestData) {
    return axios.post(ENV.appointmentApiBase + "/checks/remark/update", requestData, this.requestConfig());
  }

  static getDefaultStatuses() {
    return axios.get(ENV.apiBaseB + "/app_statuses/list", this.requestConfig());
  }

  static getChecklistReport(check_id) {
    let config = this.requestConfig();
    config = {
      ...config,
      responseType: "blob",
    };

    return axios.post(ENV.appointmentApiBase + "/checks/report/download", { check_id }, config);
  }

  static downloadPdfPreview(requestData) {
    let config = this.requestConfig();
    config = {
      ...config,
      responseType: "blob",
    };

    return axios.post(ENV.appointmentApiBase + "/repair_overview/preview/download", requestData, config);
  }

  static downloadPdfCarReadySent(requestData) {
    let config = this.requestConfig();
    config = {
      ...config,
      responseType: "blob",
    };

    return axios.post(ENV.customComBaseUrl + "/repair_overview/download", requestData, config);
  }

  static getHtmlPreview(requestData) {
    return axios.post(ENV.appointmentApiBase + "/repair_overview/preview/url", requestData, this.requestConfig());
  }

  static getChecklistReportUrl(check_id) {
    return axios.post(ENV.appointmentApiBase + "/checks/report/url", { check_id }, this.requestConfig());
  }

  static searchAppointments(requestData) {
    return axios.post(ENV.apiBaseB + "/appointments/search", requestData, this.requestConfig());
  }

  static searchCustomers(requestData) {
    return axios.post(ENV.apiBaseB + "/customers/search", requestData, this.requestConfig());
  }

  static searchKeyloopAppointments(requestData) {
    return axios.post(ENV.keyloopBaseURL + "/appointments/search", requestData, this.requestConfig());
  }

  static searchNextlaneAppointments(requestData) {
    return axios.post(ENV.nextlaneBaseUrl + "/appointments/search", requestData, this.requestConfig());
  }

  static searchKeyloopCars(requestData) {
    return axios.post(ENV.keyloopBaseURL + "/vehicles/search", requestData, this.requestConfig());
  }

  static searchNextlaneCars(requestData) {
    return axios.post(ENV.nextlaneBaseUrl + "/vehicles/search", requestData, this.requestConfig());
  }

  static searchCarsByCustomer(requestData) {
    return axios.post(ENV.carBaseUrl + "/search/customer_id", requestData, this.requestConfig());
  }

  static searchCustomersByCar(requestData) {
    return axios.post(ENV.keyloopBaseURL + "/vehicles/customers", requestData, this.requestConfig());
  }

  static searchNextlaneCustomersByCar(requestData) {
    return axios.post(ENV.nextlaneBaseUrl + "/vehicles/customers", requestData, this.requestConfig());
  }

  static getKeyloopCustomer(requestData) {
    return axios.post(ENV.keyloopBaseURL + "/customers/view", requestData, this.requestConfig());
  }

  static getNextlaneCustomer(requestData) {
    return axios.post(ENV.nextlaneBaseUrl + "/customers/view", requestData, this.requestConfig());
  }

  static getSnoozedItems(requestData) {
    return axios.post(ENV.apiBaseB + "/checks/list/snoozed", requestData, this.requestConfig());
  }

  static getCarSnoozedItems(requestData) {
    return axios.post(ENV.appointmentApiBase + "/snoozes/list_by_car_id", requestData, this.requestConfig());
  }

  static createCustomcom(requestData) {
    return axios.post(ENV.appointmentApiBase + "/diagnose_overview/create", requestData, this.requestConfig());
  }

  static updateCustomcom(requestData) {
    return axios.post(ENV.appointmentApiBase + "/diagnose_overview/update", requestData, this.requestConfig());
  }

  static createPostCustomcom(requestData) {
    return axios.post(ENV.appointmentApiBase + "/repair_overview/create", requestData, this.requestConfig());
  }

  static updatePostCustomcom(requestData) {
    return axios.post(ENV.appointmentApiBase + "/repair_overview/update", requestData, this.requestConfig());
  }

  static getDBBLink(requestData) {
    return axios.post(ENV.apiBaseB + "/appointments/portal_dbb", requestData, this.requestConfig());
  }

  static refreshDBB(requestData) {
    return axios.post(ENV.apiBaseB + "/appointments/refresh_dbb", requestData, this.requestConfig());
  }

  static deleteAppointmentAttachment(url) {
    return axios.post(ENV.apiBaseB + "/appointments/delete_attachment", { url }, this.requestConfig());
  }

  static deleteChecklist(id) {
    return axios.post(ENV.apiBaseB + "/checks/delete_last_check", { id: id }, this.requestConfig());
  }

  static getTyresSizes() {
    return axios.get(ENV.apiBaseB + "/tyres/list_dimensions", this.requestConfig());
  }

  static getTyresBySize(request_data) {
    return axios.post(ENV.apiBaseB + "/tyres/list_by_size", request_data, this.requestConfig());
  }

  static updateTyresReplacement(request_data) {
    return axios.post(ENV.apiBaseB + "/tyres/update_replacement", request_data, this.requestConfig());
  }

  static saveTyresReplacement(request_data) {
    return axios.post(ENV.apiBaseB + "/tyres/add_replacement", request_data, this.requestConfig());
  }

  static deleteTyresReplacement(request_data) {
    return axios.post(ENV.apiBaseB + "/tyres/delete_replacement", request_data, this.requestConfig());
  }

  static handleCheckInResult(request_data) {
    return axios.post(ENV.appointmentApiBase + "/check_in/result/handle", request_data, this.requestConfig());
  }

  static handleCheckInRemark(request_data) {
    return axios.post(ENV.appointmentApiBase + "/check_in/remark/handle", request_data, this.requestConfig());
  }

  static handleKeyLockerRemark(request_data) {
    return axios.post(ENV.keylockerBase + "/dashboard/remark/handle", request_data, this.requestConfig());
  }

  static handleKioskRemark(request_data) {
    return axios.post(ENV.kioskBaseUrl + "/dashboard/remark/handle", request_data, this.requestConfig());
  }

  static handleCustomComRemark(request_data) {
    return axios.post(ENV.appointmentApiBase + "/diagnose_overview/remark/handle", request_data, this.requestConfig());
  }

  static keyloopRefresh(request_data) {
    return axios.post(ENV.keyloopBaseURL + "/appointments/refresh", request_data, this.requestConfig());
  }

  static nextlaneRefresh(request_data) {
    return axios.post(ENV.nextlaneBaseUrl + "/appointments/refresh", request_data, this.requestConfig());
  }

  static autoflexRefresh(request_data) {
    return axios.post(ENV.autoflexBaseUrl + "/appointments/refresh", request_data, this.requestConfig());
  }

  static getCarByRegNum(reg_nr, location_id) {
    return axios.post(ENV.carBaseUrl + "/cars/search/regnr", { reg_nr, location_id }, this.requestConfig());
  }

  static createDeskCheckin(requestData) {
    let source = axios.CancelToken.source();
    return { request: axios.post(ENV.dcBaseUrl + "/receptionists/create", requestData, this.requestConfig(source)), token: source };
  }

  static cancelDeskCheckin(desk_communication_id) {
    return axios.post(ENV.dcBaseUrl + "/receptionists/cancel", { desk_communication_id }, this.requestConfig());
  }

  static getSnoozeAppointmentHistory(requestData) {
    return axios.post(ENV.appointmentApiBase + "/snoozes/appointment_history", requestData, this.requestConfig());
  }

  static getCancelAppointmentReasons() {
    return axios.get(ENV.apiBaseB + "/appointments/cancel_reasons ", this.requestConfig());
  }

  static getMichelinAuthToken(requestData) {
    return axios.post(ENV.apiBaseB + "/locations/michelin_auth", requestData, this.requestConfig());
  }

  static getAdvisedCriticalHistory(requestData) {
    return axios.post(ENV.appointmentApiBase + "/leads/advised_critical_history", requestData, this.requestConfig());
  }

  static searchTyreTeamTires(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/catalog/search", requestData, this.requestConfig());
  }

  static searchTires(requestData) {
    return axios.post(ENV.apiBaseB + "/tyres/search", requestData, this.requestConfig());
  }

  static addTyreTeamReplacements(requestData) {
    return axios.post(ENV.apiBaseB + "/tyres/add_tyreteam_replacement", requestData, this.requestConfig());
  }

  static addTiresReplacements(requestData) {
    return axios.post(ENV.apiBaseB + "/tyres/add_replacement", requestData, this.requestConfig());
  }

  static getTyreTeamStock(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/catalog/stock", requestData, this.requestConfig());
  }

  static getTiresBrands() {
    return axios.get(ENV.apiBaseB + "/tyres/list_brands", this.requestConfig());
  }

  static tyreTeamPlaceOrder(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/catalog/order", requestData, this.requestConfig());
  }

  static getDeliveryTypes(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/catalog/list_delivery_types", requestData, this.requestConfig());
  }

  static getAppointmentDetailLog(appointment_id) {
    return axios.post(ENV.apiBaseB + "/appointments/view_log", { appointment_id }, this.requestConfig());
  }

  static getCar(carID) {
    return axios.post(ENV.carBaseUrl + "/cars/details", { id: carID }, this.requestConfig());
  }

  static uploadAttachment(requestData) {
    return axios.post(ENV.apiBaseB + "/files/upload_appointment_attachment", requestData, this.requestConfig());
  }

  static uploadNoteAttachment(requestData) {
    return axios.post(ENV.apiBaseB + "/files/upload_appointment_note_attachment", requestData, this.requestConfig());
  }

  static uploadVideos(requestData) {
    return axios.post(ENV.apiBaseB + "/files/upload_question_result_file", requestData, this.requestConfig());
  }

  static getKeyloopDMSAppointmentID(requestData) {
    return axios.post(ENV.keyloopBaseURL + "/appointments/import", requestData, this.requestConfig());
  }

  static getNextlaneDMSAppointmentID(requestData) {
    return axios.post(ENV.nextlaneBaseUrl + "/appointments/import", requestData, this.requestConfig());
  }

  static getDiscounts(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/discounts/list_discounts", requestData, this.requestConfig());
  }

  static createBillNote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/bill/create", requestData, this.requestConfig());
  }

  static updateBillNote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/bill/update", requestData, this.requestConfig());
  }

  static createTempDriverNote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/temp_driver/create", requestData, this.requestConfig());
  }

  static updateTempDriverNote(requestData) {
    return axios.post(ENV.appointmentApiBase + "/notes/temp_driver/update", requestData, this.requestConfig());
  }

  static getAllAppointmentAttachments(requestData) {
    return axios.post(ENV.apiBaseB + "/files/list_appointment_attachments", requestData, this.requestConfig());
  }

  static updateIsAppointmentPinned(requestData) {
    return axios.post(ENV.apiBaseB + "/appointments/update_pin", requestData, this.requestConfig());
  }

  static updateOnlineCheckinRemark(requestData) {
    return axios.post(ENV.appointmentApiBase + "/check_in/remark/update", requestData, this.requestConfig());
  }

  static updateDiagnoseRemark(requestData) {
    return axios.post(ENV.appointmentApiBase + "/diagnose_overview/remark/update", requestData, this.requestConfig());
  }

  static updateKeylockerRemark(requestData) {
    return axios.post(ENV.keylockerBase + "/dashboard/remark/update", requestData, this.requestConfig());
  }

  static updateKioskCheckinRemark(requestData) {
    return axios.post(ENV.kioskBaseUrl + "/dashboard/remark/update", requestData, this.requestConfig());
  }

  static restoreCanceledAppointemnt(requestData) {
    return axios.post(ENV.apiBaseB + "/appointments/restore", requestData, this.requestConfig());
  }

  static getSuggestionsList(requestData) {
    return axios.post(ENV.apiBaseB + "/appointment_notes/list_comment_suggestions", requestData, this.requestConfig());
  }

  static kioskLabelDetach(requestData) {
    return axios.post(ENV.kioskBaseUrl + "/dashboard/labels/detach", requestData, this.requestConfig());
  }

  static updateCustomerWaiting(requestData) {
    return axios.post(ENV.appointmentApiBase + "/appointments/set_customer_waiting", requestData, this.requestConfig());
  }
}

export default Service;
