import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Dropdown, Button } from "semantic-ui-react";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";
import moment from "moment";

import { DateRange } from "../../components";
import { SimpleBarChart } from "../LocationReports/charts/SimpleBarChart";
import { translateDataForCharts } from "../../util/common";
import { LocationsOverview } from "./tables/locations_overview";
import { LocationsCommercialOverview } from "./tables/locations_commercial_overview";
import { LocationsGlobalOverview } from "./tables/locations_global_overview";
import { LeaseOverview } from "./tables/lease_overview";
import { RecurringCars } from "./tables/recurring_cars";
import { QualityChecks } from "./tables/quality_checks";
import { CommunicationsOverview } from "./tables/communications_overview";
import { OnlineCheckInPerformances } from "./tables/online_checkin_performances";
import { DiagnoseOverviewPerformances } from "./tables/diagnose_overview_performances";
import { LocationCanceledAppointments } from "./tables/location_canceled_appointments";
import { KeylockerPin } from "./tables/keylocker_pin";
import { SnoozedItems } from "./tables/snoozed_items";
import { SnoozedNextVisitCustomer } from "./tables/snooze_next_visit_customer";
import { SnoozedMakeOffer } from "./tables/snooze_make_offer";
import { SnoozedMakeNewAppointment } from "./tables/snooze_make_new_appointment";
import { SnoozedRemindCustomer } from "./tables/snooze_remind_customer";
import { SMSEvents } from "./tables/sms_events";
import { SMSOverview } from "./tables/sms_overview";
import { ShareboxKeylockerOverview } from "./tables/sharebox_keylocker_overview";
import { TyreTeamOverview } from "./tables/tyreteam_overview";
import { TyreOverview } from "./tables/tyre_overview";
import { KioskOverview } from "./tables/kiosk_overview";
import EmailStats from "./EmailStats";

import Service from "./service";

import "./dealerReports.css";

class DealerReports extends Component {
  state = {
    groups: [],
    selectedGroup: null,
    days: 7,
    dateFrom: moment().subtract(7, "d").format("YYYY-MM-DDT12:00:00Z"),
    dateTo: moment().subtract(1, "d").format("YYYY-MM-DDT12:00:00Z"),
    report_list: [],
    report_data: [],
    isLoadingList: false,
    isLoadingReport: false,
    sevenDaysData: [],
    monthData: [],
    threeMonthsData: [],
  };

  unmounted = false;

  componentDidUpdate(prevProps) {
    if (prevProps.globalState.selectedDealer.id !== this.props.globalState.selectedDealer.id) {
      this.changeDealer();
    }
  }

  componentDidMount() {
    this.changeDealer();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  changeDealer = () => {
    this.setState({ isLoadingList: true }, () => {
      Service.getReportGroups(this.props.globalState.selectedDealer.id)
        .then(res => {
          let groups = [];
          if (Array.isArray(res.data?.data?.groups)) groups = res.data.data.groups;

          this.setState({ isLoadingList: false, groups, selectedGroup: groups[0] }, () => {
            this.loadReportsList();
          });
        })
        .catch(err => {
          console.log(err);
          this.setState({ isLoadingList: false });
        });
    });
  };

  loadReportsList = () => {
    this.setState({ isLoadingList: true }, async () => {
      let report_list = [];
      const { groups } = this.state;

      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        try {
          let res = await Service.getReportList(this.props.globalState.selectedDealer.id, group);
          if (Array.isArray(res.data?.data?.template_ids)) report_list[group] = res.data.data.template_ids;
          else report_list[group] = [];
        } catch (err) {
          console.log(err);
        }
      }

      this.setState({ report_list, report_data: [], sevenDaysData: [], monthData: [], threeMonthsData: [], isLoadingList: false }, () => {
        this.loadReports();
      });
    });
  };

  getGroupDropdown = () => {
    return this.state.groups.map(group => ({ key: group, text: this.props.t("report_group_" + group).message || "Group " + group, value: group }));
  };

  getPeriodDropdown = () => {
    return [
      {
        key: "7",
        text: "7 " + (this.props.t("days").message || "days"),
        value: 7,
      },
      {
        key: "30",
        text: "30 " + (this.props.t("days").message || "days"),
        value: 30,
      },
      {
        key: "90",
        text: "90 " + (this.props.t("days").message || "days"),
        value: 90,
      },
      {
        key: "custom",
        text: this.props.t("custom").message || "Custom",
        value: "custom",
      },
    ];
  };

  sortReports = (a, b) => {
    return a.report_order - b.report_order;
  };

  handleSetData = (_days, data) => {
    let { sevenDaysData, monthData, threeMonthsData, report_data, days } = this.state;

    if (_days === 7) {
      sevenDaysData.push(data);
      sevenDaysData.sort(this.sortReports);
    } else if (_days === 30) {
      monthData.push(data);
      monthData.sort(this.sortReports);
    } else if (_days === 90) {
      threeMonthsData.push(data);
      threeMonthsData.sort(this.sortReports);
    }

    if (_days === days) {
      report_data.push(data);
      report_data.sort(this.sortReports);
    }

    this.setState({ isLoadingReport: false, sevenDaysData, monthData, threeMonthsData, report_data });
  };

  handleLoadFromCache = data => {
    this.setState({ report_data: [...data] }, () => {
      if (data.length < this.state.report_list.length) this.loadReports();
    });
  };

  handleChangeGroup = (_, data) => {
    this.setState({ selectedGroup: data.value, report_data: [], sevenDaysData: [], monthData: [], threeMonthsData: [] }, () => this.loadReports());
  };

  handleChangeDays = (_, { value: days }) => {
    if (days === "custom") {
      this.setState({ days });
      return;
    }

    const dateFrom = moment().subtract(days, "d").format("YYYY-MM-DDT12:00:00Z");
    const dateTo = moment().subtract(1, "d").format("YYYY-MM-DDT12:00:00Z");

    this.setState({ days, dateFrom, dateTo }, () => {
      if (days === 7) {
        this.handleLoadFromCache(this.state.sevenDaysData);
      } else if (days === 30) {
        this.handleLoadFromCache(this.state.monthData);
      } else if (days === 90) {
        this.handleLoadFromCache(this.state.threeMonthsData);
      }
    });
  };

  handleChangeCustomDates = ([dateFrom, dateTo]) => {
    this.setState({ dateFrom, dateTo }, () => {
      if (this.state.dateFrom && this.state.dateTo) {
        this.setState({ report_data: [] }, () => this.loadReports());
      }
    });
  };

  loadReports = () => {
    let { selectedGroup, report_list, days, dateFrom, dateTo } = this.state;
    let { id } = this.props.globalState.selectedDealer;

    (async (dID, report_to_load, selectedGroup, days, dateFrom, dateTo) => {
      while (report_to_load.length > 0) {
        if (
          this.unmounted ||
          this.props.globalState.selectedDealer.id !== dID ||
          this.state.selectedGroup !== selectedGroup ||
          this.state.dateFrom !== dateFrom ||
          this.state.dateTo !== dateTo
        )
          break;

        let reportID = report_to_load.shift();

        if (this.state.report_data.findIndex(r => r.id === reportID) > -1) continue;

        this.setState({ isLoadingReport: true });

        try {
          const startDate = moment(dateFrom).format("YYYY-MM-DD[T]00:00:00[Z]");
          const endDate = moment(dateTo).format("YYYY-MM-DD[T]23:59:59[Z]");

          let res = await Service.getReportData(dID, reportID, startDate, endDate);

          if (
            this.unmounted ||
            this.props.globalState.selectedDealer.id !== dID ||
            this.state.selectedGroup !== selectedGroup ||
            this.state.dateFrom !== dateFrom ||
            this.state.dateTo !== dateTo
          )
            break;

          if (res?.data?.data) this.handleSetData(days, res.data.data);
        } catch (err) {
          console.log(err);
          if (
            !this.unmounted &&
            this.props.globalState.selectedDealer.id === dID &&
            this.state.selectedGroup === selectedGroup &&
            this.state.dateFrom === dateFrom &&
            this.state.dateTo === dateTo &&
            report_to_load.length < 1
          )
            this.setState({ isLoadingReport: false });
        }
      }
    })(id, [...report_list[selectedGroup]], selectedGroup, days, dateFrom, dateTo);
  };

  exportData = async (id, report_name, data) => {
    const { t } = this.props;

    let csv = "";
    let header = (t("week").message || "Week #") + ", ";
    header += (t("location").message || "location") + ", ";

    let values = [];
    if (id === 2) {
      header += t("wo_geclaired_percentage").message || "% WO worked on";

      data.forEach(d => {
        Object.keys(d)
          .reduce((acc, cur) => {
            if (cur !== "name" && !cur.startsWith("total")) acc.push([cur, d[cur]]);
            return acc;
          }, [])
          .sort((a, b) => (a[0] < b[0] ? -1 : 1))
          .forEach(s => values.push(d.name + ", " + s[0] + ", " + s[1]));
      });
    } else if (id === 3) {
      header += t("percent_items_sold").message || "% items sold";

      data.forEach(d => {
        Object.keys(d)
          .reduce((acc, cur) => {
            if (cur !== "name" && !cur.startsWith("total")) acc.push([cur, d[cur]]);
            return acc;
          }, [])
          .sort((a, b) => (a[0] < b[0] ? -1 : 1))
          .forEach(s => values.push(d.name + ", " + s[0] + ", " + s[1]));
      });
    }

    csv += header + "\n" + values.join("\n");
    saveAs(new File([csv], { type: "text/csv;charset=utf-8" }), report_name.replace(" ", "_") + "_" + moment().format("YYYYMMDD") + ".csv");
  };

  renderReport = report => {
    if (!report.report_data) {
      return (
        <>
          <h3 className="report-heading">{this.props.t(report.report_name).message || report.report_name}</h3>
          <p className="no-data">{this.props.t("no_data").message || "No data"}</p>
        </>
      );
    }

    switch (report.id) {
      case 1:
        return <LocationsOverview report={report} t={this.props.t} />;

      case 4:
        return <RecurringCars report={report} t={this.props.t} />;

      case 5:
        return <LocationsCommercialOverview report={report} t={this.props.t} />;

      case 6:
        return <QualityChecks report={report} t={this.props.t} />;

      case 7:
        return <CommunicationsOverview report={report} t={this.props.t} />;

      case 8:
        return <OnlineCheckInPerformances report={report} t={this.props.t} />;

      case 9:
        return <DiagnoseOverviewPerformances report={report} t={this.props.t} />;

      case 10:
        return <LocationsGlobalOverview report={report} t={this.props.t} />;

      case 11:
        return <LocationCanceledAppointments report={report} t={this.props.t} />;

      case 12:
        return <LeaseOverview report={report} t={this.props.t} />;

      case 13:
        return <SnoozedItems report={report} t={this.props.t} />;

      case 14:
        return <SnoozedNextVisitCustomer report={report} t={this.props.t} />;

      case 15:
        return <SnoozedMakeOffer report={report} t={this.props.t} />;

      case 16:
        return <SnoozedMakeNewAppointment report={report} t={this.props.t} />;

      case 17:
        return <SnoozedRemindCustomer report={report} t={this.props.t} />;

      case 18:
        return <KeylockerPin report={report} t={this.props.t} />;

      case 19:
        return <SMSOverview report={report} t={this.props.t} />;

      case 20:
        return <SMSEvents report={report} t={this.props.t} />;

      case 21:
        return <ShareboxKeylockerOverview report={report} t={this.props.t} />;

      case 22:
        return <TyreOverview report={report} t={this.props.t} />;

      case 23:
        return <TyreTeamOverview report={report} t={this.props.t} />;

      case 25:
        return <KioskOverview report={report} t={this.props.t} />;

      default:
        if (report.report_type === "SimpleBarChart") {
          const data = report.report_data.map(data => translateDataForCharts(data, this.props.t));
          const thresholds = report.thresholds ? JSON.parse(report.thresholds) : [];
          if (thresholds.length > 0) {
            thresholds.forEach(th => {
              if (th.name) th.name = this.props.t(th.name).message || th.name;
            });
          }

          return (
            <>
              <h3 className="report-heading">
                {this.props.t(report.report_name).message || report.report_name}
                <Button color="green" floated="right" basic onClick={() => this.exportData(report.id, report.report_name, data)}>
                  <span style={{ marginRight: "10px" }}>{this.props.t("export").message || "Export"}</span>
                  <FontAwesomeIcon icon={faFileExport} />
                </Button>
              </h3>
              <SimpleBarChart colors={report.report_colors || []} thresholds={thresholds} unit={report.unit} t={this.props.t} data={data} />
            </>
          );
        }

        return null;
    }
  };

  render() {
    let { report_data, isLoadingList, isLoadingReport, selectedGroup, days, dateFrom, dateTo } = this.state;

    if (isLoadingList) {
      return (
        <div className="Loader">
          <Loader type="Oval" color="#46923d" height="100" width="100" />
        </div>
      );
    }

    return (
      <div className="dealer-reports-outter-container">
        <div className="reports-header">
          <h2 className="reports-heading">{this.props.t("dealer_reports").message || "Dealer reports"}</h2>
          <div className="dropdowns-container">
            <div className="dropdown-container">
              <div className="dropdown-lable">{this.props.t("reports").message || "Reports"}</div>
              <Dropdown
                className="reports-days-dropdown"
                onChange={this.handleChangeGroup}
                value={this.state.selectedGroup}
                selection
                options={this.getGroupDropdown()}
              />
            </div>
            <div className="dropdown-container">
              <div className="dropdown-lable">{this.props.t("select_time_period").message || "Select time period"}</div>
              <Dropdown className="reports-days-dropdown" onChange={this.handleChangeDays} value={this.state.days} selection options={this.getPeriodDropdown()} />
            </div>
            {days === "custom" && (
              <div className="dropdown-container">
                <div className="dropdown-lable">{this.props.t("select_date_range").message || "Select date range"}</div>
                <DateRange dateFrom={dateFrom} dateTo={dateTo} onChange={this.handleChangeCustomDates} maxDate={moment(dateFrom).add(3, "M").toDate()} />
              </div>
            )}
          </div>
        </div>

        <div className="dealers-reports-container">
          <div className="dealers-reports-content-container">
            <div className={"report-full-width"}>
              {report_data.length > 0 &&
                report_data.map((report, i) => {
                  return (
                    <div className={"report-box-full-width"} key={i}>
                      {this.renderReport(report)}
                    </div>
                  );
                })}

              {isLoadingReport && (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <Loader type="Oval" color="#46923d" height="100" width="100" />
                </div>
              )}
            </div>
          </div>

          {selectedGroup === 3 && (
            <div className="dealers-reports-content-container">
              <EmailStats dealerID={this.props.globalState.selectedDealer.id} period={moment(dateTo).diff(moment(dateFrom), "d") + "d"} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

export default withTranslation()(connect(mapStateToProps, {})(DealerReports));
