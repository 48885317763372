import React, { Component } from "react";
import { Icon, Segment, Grid, Header } from "semantic-ui-react";
import { connect } from "react-redux";

import GeneralSettings from "./GeneralSettings";
import LocationEdit from "./LocationEdit";
import DetailSettings from "./DetailSettings";
import KeyloopConfiguration from "./KeyloopConfiguration";
import TyreTeamConfiguration from "./TyreTeamConfiguration";
import ShareboxConfiguration from "./ShareboxConfiguration";
import EVHCConfiguration from "./EVHCConfiguration";
import DmsSettings from "./DmsSettings";
import AppointmentUnpinConfiguration from "./AppointmentUnpinConfiguration";
import DayplannerConfiguration from "./DayplannerConfiguration";
import CommunicationSettings from "./CommunicationSettings";
import ROBNetConfiguration from "./ROBNetConfiguration";
import AcsesConfiguration from "./AcsesConfiguration";
import RdwConfiguration from "./RdwConfiguration";
import PinConfiguration from "./PinConfiguration";
import InvoicingSettings from "./InvoicingSettings";

class LocationDetail extends Component {
  state = {
    deleteModalOpen: false,
  };

  componentDidMount() {
    document.addEventListener("keydown", this.escapeModal, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escapeModal, false);
  }

  handleLocationChange = (location, allowSave = true) => {
    this.props.onChangeSelectedLocation({ ...location });
    this.props.onUpdateThresholdValid && this.props.onUpdateThresholdValid(allowSave);
  };

  render() {
    const {
      globalState: { dealers },
      location,
      t,
      isAdmin,
      languages,
      isDealersAdminPage,
      isSubmit,
    } = this.props;
    const dealer = dealers.find(dealer => dealer.id === location.dealer_id);

    return (
      <div className="LocationDetail">
        <Grid columns="equal">
          <Grid.Column>
            <Segment placeholder>
              <Header as="h5">
                <Icon name="warehouse" />
                <Header.Content>{t("location_settings").message || "Location algemeen"}</Header.Content>
              </Header>
              <Segment>
                <LocationEdit
                  isSubmit={isSubmit}
                  dealer={dealer}
                  location={location}
                  languages={languages}
                  handleChange={this.handleLocationChange}
                  isAdmin={isAdmin}
                  t={t}
                  {...this.props}
                />
              </Segment>
            </Segment>
          </Grid.Column>

          {!this.props.isDetailConfigurationHidden && (
            <Grid.Column>
              <Segment placeholder>
                <DetailSettings isSubmit={isSubmit} dealer={dealer} location={location} handleChange={this.handleLocationChange} t={t} {...this.props} />
                <DmsSettings location={location} onLocationChange={this.handleLocationChange} />
              </Segment>

              {isDealersAdminPage && (
                <>
                  <KeyloopConfiguration t={t} location={location} handleChange={this.handleLocationChange} />
                  <TyreTeamConfiguration location={location} onLocationChange={this.handleLocationChange} />
                  <ShareboxConfiguration location={location} onLocationChange={this.handleLocationChange} />
                  <EVHCConfiguration location={location} onLocationChange={this.handleLocationChange} />
                </>
              )}
            </Grid.Column>
          )}

          {!this.props.isGeneralSettingsHidden && (
            <Grid.Column>
              <Segment>
                <Segment.Inline>
                  <GeneralSettings isSubmit={isSubmit} location={location} handleChange={this.handleLocationChange} isAdmin={isAdmin} t={t} {...this.props} />
                </Segment.Inline>
              </Segment>
            </Grid.Column>
          )}

          <Grid.Column>
            <AppointmentUnpinConfiguration location={location} onLocationChange={this.handleLocationChange} />
            <InvoicingSettings location={location} onLocationChange={this.handleLocationChange} />

            {isDealersAdminPage && (
              <>
                <DayplannerConfiguration location={location} onLocationChange={this.handleLocationChange} />
                <CommunicationSettings location={location} onLocationChange={this.handleLocationChange} />
                <ROBNetConfiguration location={location} onLocationChange={this.handleLocationChange} />
                <AcsesConfiguration location={location} onLocationChange={this.handleLocationChange} isSubmit={isSubmit} />
                <RdwConfiguration location={location} onLocationChange={this.handleLocationChange} />
                <PinConfiguration location={location} onLocationChange={this.handleLocationChange} brands={this.props.brands} />
              </>
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    globalState: state.global,
  };
};

export default connect(mapStateToProps)(LocationDetail);
