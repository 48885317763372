import React from "react";
import { Grid, Icon, Label, Popup } from "semantic-ui-react";
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSun, faSnowflake } from "@fortawesome/free-solid-svg-icons";
import { faSunDust } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";

import { removeGenerationParam, getYYYMMDD } from "./../../util/common";

import Service from "./service";

export const COMMUNICATION_STATUSES = {
  ONLINE_CHECKIN_SENT: 1,
  ONLINE_CHECKIN_OPENED: 2,
  ONLINE_CHECKIN_ANSWERED: 3,
  ONLINE_CHECKIN_CLOSED: 4,
  DIAGNOSE_OVERVIEW_SENT: 5,
  DIAGNOSE_OVERVIEW_OPENED: 6,
  DIAGNOSE_OVERVIEW_ANSWERED: 7,
  DIAGNOSE_OVERVIEW_CLOSED: 8,
  REPAIR_OVERVIEW_SENT: 9,
  REPAIR_OVERVIEW_OPENED: 10,
};

export const COMMUNICATION_EVENTS = {
  ONLINE_CHECKIN_SENT: 1,
  ONLINE_CHECKIN_OPENED: 2,
  ONLINE_CHECKIN_ANSWERED: 3,
  DIAGNOSE_OVERVIEW_SENT: 4,
  DIAGNOSE_OVERVIEW_OPENED: 5,
  DIAGNOSE_OVERVIEW_ANSWERED: 6,
  DIAGNOSE_OVERVIEW_RESENT: 7,
  REPAIR_OVERVIEW_SENT: 8,
  REPAIR_OVERVIEW_RESENT: 9,
  REPAIR_OVERVIEW_OPENED: 10,
};

export const KIOSK_COMMUNICATION_STATUSES = {
  CHECKIN_OPENED: 1,
  CHECKIN_ANSWERED: 2,
};

export const KIOSK_COMMUNICATION_EVENTS = {
  CHECKIN_OPENED: 1,
  CHECKIN_ANSWERED: 2,
  LABEL_DETACHED: 3,
};

export const KEYLOCKER_COMMUNICATION_STATUSES = {
  DROP_PIN_SENT: 1,
  CHECK_IN_OPENED: 2,
  CHECK_IN_ANSWERED: 3,
  PICK_UP_PIN_SENT: 4,
};

export const KEYLOCKER_COMMUNICATION_EVENTS = {
  DROP_PIN_SENT: 1,
  CHECK_IN_OPENED: 2,
  CHECK_IN_ANSWERED: 3,
  PICK_UP_PIN_SENT: 4,
};

export const DESK_COMMUNICATION_STATUSES = {
  CHECKIN_SENT: 1,
  CHECKIN_RECEIVED: 2,
  CHECKIN_FAILED_TO_OPEN: 3,
  CHECKIN_OPENED: 4,
  CHECKIN_ANSWERED: 5,
  CHECKIN_CLOSED: 6,
};
export const DESK_COMMUNICATION_EVENTS = {
  CHECKIN_SENT: 1,
  CHECKIN_RECEIVED: 2,
  CHECKIN_FAILED_TO_OPEN: 3,
  CHECKIN_OPENED: 4,
  CHECKIN_CANCELED: 5,
  CHECKIN_ANSWERED: 6,
};

export const COMMUNICATION_RESULT_STATUSES = {
  NOT_ANSWERED: 1,
  OK: 2,
  NOT_OK: 3,
  CONTACT_ME: 4,
  AGREED: 5,
};

export const COMMUNICATION_RECEIVER_STATUSES = {
  PENDING_STATUS: 1,
  SUCCESS_STATUS: 2,
  FAILED_STATUS: 3,
};

export const CHANNELS = {
  EMAIL: 1,
  SMS: 2,
  WEBHOOK: 3,
};

export const TYRE_POSITION = {
  NOT_A_TYRE: 0,
  CAR_FRONT_LEFT: 1,
  CAR_FRONT_RIGHT: 2,
  CAR_REAR_LEFT: 3,
  CAR_REAR_RIGHT: 4,
  STORAGE_FRONT_LEFT: 5,
  STORAGE_FRONT_RIGHT: 6,
  STORAGE_REAR_LEFT: 7,
  STORAGE_REAR_RIGHT: 8,
  CAR_SPARE: 9,
  TRUCK_LEFT: 10,
  TRUCK_RIGHT: 11,
  TRUCK_EXTERIOR_LEFT: 12,
  TRUCK_EXTERIOR_RIGHT: 13,
  TRUCK_INTERIOR_LEFT: 14,
  TRUCK_INTERIOR_RIGHT: 15,
  TRUCK_SPARE: 16,
  IS_ON_CAR: position => position > 0 && position < 5,
  IS_IN_STORAGE: position => position > 4 && position < 9,
  IS_ON_TRUCK: position => position > 9,
};

export const SEASONS = {
  NO_SEASON: -1,
  ALL_SEASONS: 0,
  WINTER: 1,
  SUMMER: 2,
  NOT_AVAILABLE: 3,
};

export const APPOINTMENT_STATUSES = {
  CANCELED: -1,
  NEW_CAR: 1,
  CAR_CHECK: 2,
  PRICING_OK: 3,
  CUSTOMER_OK: 4,
  CAR_READY: 5,
  DIAGNOSE: 6,
  INITIAL_CHECK_DONE: 7,
  CALCULATING: 8,
  CONTACT: 9,
  CAR_CHECK_STARTED: 10,
  QUALITY_CHECK: 11,
  CUSTOMER_ANSWERED: 12,
  LAST_REAL_STATUS: 12,
  BACK_ORDER: 13,
  HANDLE_CHECK_IN: 50,
  CHECK_IN_DONE: 51,
  CAR_IN_SHOP: 52,
  CAR_OK_PLUS_REPAIR_OVERVIEW: 53,
  QUALITY_CHECK_PLUS_REPAIR_OVERVIEW: 54,
  CAR_OUT_OF_SHOP: 55,
};

export const isRealStatus = identifier => identifier <= APPOINTMENT_STATUSES.LAST_REAL_STATUS || identifier === APPOINTMENT_STATUSES.BACK_ORDER;

export const QUESTION_RESULT_STATUS = {
  OK: 0,
  OK_WITH_REMARKS: 1,
  ADVISED: 2,
  NECESSARY: 3,
  NOT_WORKED_ON: 4,
};

export const TYRE_TEAM_CHANNEL = {
  FIRST: 1,
  WHEEL_TYRE: 1,
  BANDEN_EXPRESS: 2,
  LAST: 2,
};

export const INTERVENTION_ELEMENT_STATUS = {
  ACTIVE: 1,
  ON_HOLD: 2,
  DELETED: 3,
};

export const INTERVENTION_ELEMENT_UNIT = {
  AMOUNT: 1,
  AMOUNT_PER_HOUR: 2,
  QUANTITY: 3,
};

export const INTERVENTION_ELEMENT_TYPE = {
  TASK: 1,
  LABOR: 2,
  PART: 3,
  TEXT: 4,
  DISCOUNT: 5,
  SMALL_PART: 6,
  FEE: 7,
  RENT: 8,
  MENU: 9,
};

export const STATUS_OVERRIDE = {
  NONE: 0,
  CANCELED: 1,
  RESTORED: 2,
};

export const WO_FILTERS = {
  ALL: 1,
  WO: 2,
  INTERNAL: 3,
};

export const CHECK_EVENT_TYPE = {
  STARTED: 1,
  PAUSED: 2,
  RESUMED: 3,
  ABORTED: 4,
  COMPLETED: 5,
};

export const renderReadyStatus = isFixed => {
  if (isFixed)
    return (
      <Label style={{ backgroundColor: "white", color: "white" }}>
        <Icon color="green" name="check" />
      </Label>
    );

  return (
    <Label style={{ backgroundColor: "white", color: "white" }}>
      <Icon color="red" name="x" className="-translate-y-1" />
    </Label>
  );
};

export const uploadVideos = (ev, location, appointment) => {
  const uploadFile = ev.target.files[0];

  const date = moment(appointment.created_on).format("YYYY/MM/DD");

  const path = `${location.id}_${location.name}/${date}/${appointment.id}_${appointment.wo_nr}`;

  const formData = new FormData();
  formData.append("file", uploadFile);
  formData.append("path", path);

  return Service.uploadVideos(formData);
};

export const renderMedia = (
  isCustomerCommunicationVisible,
  appointment,
  images,
  videos,
  onMediaClick,
  onMediaUpdate,
  isEditable = true,
  files = [],
  question_result_id = null,
  location = null
) => {
  let hasImages = true;
  let hasVideos = true;
  let hasFiles = true;

  if (!images || images.length === 0) {
    hasImages = false;
  }

  if (!videos || videos.length === 0) {
    hasVideos = false;
  }

  if (!files || files.length === 0) {
    hasFiles = false;
  }

  if (!hasImages && !hasVideos && !hasFiles) {
    return "";
  }

  return (
    <Grid.Column width={16} className="AppointmentChecklistGroup__item__media">
      <div>
        {images &&
          images.map((image, k) => (
            <div className="AppointmentChecklistGroup__item__media__Image-Wrapper" key={k}>
              <img src={removeGenerationParam(image.url)} alt="Check" onClick={() => onMediaClick({ visibleImages: images, itemIndex: k })} />
              <section>
                {isEditable && (
                  <Icon
                    name={image.visible_in_pdf ? "eye" : "low vision"}
                    color={image.visible_in_pdf ? "green" : "grey"}
                    onClick={() => {
                      const payload = {
                        type: "images",
                        shouldSave: true,
                        allImages: images,
                        value: {
                          image: {
                            ...image,
                            visible_in_pdf: !image.visible_in_pdf,
                          },
                          index: k,
                        },
                      };

                      if (question_result_id) payload.questionId = question_result_id;
                      onMediaUpdate(payload);
                    }}
                  />
                )}

                {isEditable && !isCustomerCommunicationVisible && (
                  <Icon
                    name="remove"
                    color="red"
                    className="-pull-right"
                    onClick={() => {
                      const payload = {
                        type: "images",
                        shouldSave: true,
                        shouldConfirm: true,
                        allImages: images,
                        value: {
                          image: {
                            ...image,
                            visible_in_pdf: false,
                            active: false,
                          },
                          index: k,
                        },
                      };

                      if (question_result_id) payload.questionId = question_result_id;
                      onMediaUpdate(payload);
                    }}
                  />
                )}
                {!isCustomerCommunicationVisible && (
                  <Icon
                    name="download"
                    color="blue"
                    className="-pull-right -margin-right-10"
                    onClick={() => {
                      saveAs(image.url, "Car_" + appointment.reg_number_escaped + "_WO_" + appointment.wo_nr + "_" + getYYYMMDD() + "_" + (k + 1) + ".jpg");
                    }}
                  />
                )}
              </section>
            </div>
          ))}

        {files &&
          files.map((file, k) => (
            <div className="AppointmentChecklistGroup__item__media__Files-Wrapper" key={k}>
              <div className="file-wrapper-inner-containers">
                <FontAwesomeIcon
                  style={{ color: "#a6a6a6", height: "2em", width: "2em", cursor: "pointer", marginRight: "0.3em" }}
                  icon={faDownload}
                  onClick={() => {
                    saveAs(file.url, file.name);
                  }}
                />
                <Popup content={file.name} trigger={<span>{file.name.slice(0, 10) + "..."}</span>} />
              </div>
            </div>
          ))}

        {videos &&
          videos.map((video, l) => (
            <div className="AppointmentChecklistGroup__item__media__Video-Wrapper" key={l}>
              <video controls key={video.url}>
                <source src={video.url} type="video/mp4" />
                <source src={video.url} type="video/quicktime" />
                Your browser does not support the video tag.
              </video>

              <section>
                {isEditable && (
                  <Icon
                    name={video.visible ? "eye" : "low vision"}
                    color={video.visible ? "green" : "grey"}
                    onClick={() => {
                      const payload = {
                        type: "videos",
                        shouldSave: true,
                        shouldConfirm: false,
                        allVideos: videos,
                        value: {
                          video: {
                            ...video,
                            visible: !video.visible,
                            active: true,
                          },
                          index: l,
                        },
                      };

                      if (question_result_id) payload.questionId = question_result_id;
                      onMediaUpdate(payload);
                    }}
                  />
                )}

                {!isCustomerCommunicationVisible && isEditable && (
                  <Icon
                    name="remove"
                    color="red"
                    className="-pull-right"
                    onClick={() => {
                      const payload = {
                        type: "videos",
                        shouldSave: true,
                        shouldConfirm: true,
                        allVideos: videos,
                        value: {
                          video: {
                            ...video,
                            visible: false,
                            active: false,
                          },
                          index: l,
                        },
                      };

                      if (question_result_id) payload.questionId = question_result_id;
                      onMediaUpdate(payload);
                    }}
                  />
                )}
                {!isCustomerCommunicationVisible && location && location.is_editing_question_videos_enabled && (
                  <>
                    <Icon
                      name="upload"
                      color="blue"
                      className="-pull-right -margin-right-10"
                      onClick={() => {
                        document.getElementById("upload-video").click();
                      }}
                    />

                    <input
                      type="file"
                      accept=".mp4, .mov"
                      style={{ display: "none" }}
                      id="upload-video"
                      onChange={async ev => {
                        try {
                          const allowedFormats = ["video/mp4", "video/quicktime"];
                          if (!allowedFormats.includes(ev.target.files[0].type)) return;

                          const response = await uploadVideos(ev, location, appointment);

                          if (response?.data?.data?.URL) {
                            const payload = {
                              type: "videos",
                              shouldSave: true,
                              shouldConfirm: false,
                              allVideos: videos,
                              value: {
                                video: {
                                  ...video,
                                  url: response.data.data.URL,
                                },
                                index: l,
                              },
                            };

                            if (question_result_id) payload.questionId = question_result_id;
                            onMediaUpdate(payload);
                          }
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    />
                  </>
                )}
                {!isCustomerCommunicationVisible && (
                  <Icon
                    name="download"
                    color="blue"
                    className="-pull-right -margin-right-10"
                    onClick={() => {
                      saveAs(video.url, "Car_" + appointment.reg_number_escaped + "_WO_" + appointment.wo_nr + "_" + getYYYMMDD() + "_" + (l + 1) + ".mp4");
                    }}
                  />
                )}
              </section>
            </div>
          ))}
      </div>
    </Grid.Column>
  );
};

export const getTyreSeasonIcon = season => {
  switch (season) {
    case SEASONS.ALL_SEASONS:
      return (
        <Label className="Tyre_season_icon" color={"green"}>
          <FontAwesomeIcon style={{ margin: "0px" }} icon={faSunDust} className="icon" />
        </Label>
      );

    case SEASONS.SUMMER:
      return (
        <Label className="Tyre_season_icon" color={"yellow"}>
          <FontAwesomeIcon style={{ margin: "0px" }} icon={faSun} className="icon" />
        </Label>
      );

    case SEASONS.WINTER:
      return (
        <Label className="Tyre_season_icon" color={"blue"}>
          <FontAwesomeIcon style={{ margin: "0px" }} icon={faSnowflake} className="icon" />
        </Label>
      );

    default:
      return null;
  }
};
